<script>
import layoutProfile from "@/views/layouts/layoutProfile";

export default {
	name: "Terms",
	components: {
		layoutProfile,
	},
}
</script>

<template>
	<layoutProfile>
		<div class="w-100"
			 style="overflow-x: hidden;overflow-y: auto;max-height: calc(100vh - 108px);margin-bottom: 40px;">
			<h1 class="px-4 pt-4 mb-0">Terms of Service</h1>
			<div class="px-4 pt-4 mb-0">
				<h5>1. Introduction</h5>
				<p>Welcome to Romeo! These Terms of Service ("Terms") govern your access to and use of the Romeo
					website, mobile application, and related services (collectively, the "Service"). By accessing or
					using the Service, you agree to be bound by these Terms.</p>
				<h5>2. Your Use of the Service</h5>
				<p>2.1 Eligibility: You must be at least 18 years old to use the Service. By using the Service, you
					represent and warrant that you are of legal age to form a binding contract.</p>
				<p>2.2 Registration: In order to access certain features of the Service, you may be required to create
					an account. You agree to provide accurate, current, and complete information during the registration
					process.</p>
				<p>2.3 User Content: You are solely responsible for any content you post, upload, or transmit through
					the Service ("User Content"). By submitting User Content, you grant Romeo a worldwide,
					non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, translate,
					distribute, and display such User Content.</p>
				<h5>3. Privacy</h5>
				<p>Your privacy is important to us. Please review our Privacy Policy for information on how we collect,
					use, and disclose your personal information.</p>
				<h5>4. Prohibited Conduct</h5>
				<p>4.1 You agree not to:</p>
				<p>(a) Use the Service for any unlawful purpose or in violation of these Terms;</p>
				<p>(b) Impersonate any person or entity, or falsely state or misrepresent your affiliation with a person
					or entity;</p>
				<p>(c) Interfere with or disrupt the Service or servers or networks connected to the Service;</p>
				<p>(d) Harass, abuse, or harm another person, or engage in any other conduct that is harmful,
					threatening, or offensive;</p>
				<p>(e) Use any automated system or software to extract data from the Service;</p>
				<p>(f) Access or use the Service for any commercial purpose without our prior written consent.</p>
				<h5>5. Intellectual Property</h5>
				<p>All content and materials available on the Service, including text, graphics, logos, images, and
					software, are the property of Romeo or its licensors and are protected by copyright, trademark, and
					other intellectual property laws.</p>
				<h5>6. Disclaimer of Warranties</h5>
				<p>THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT WARRANTIES OF ANY KIND,
					EITHER EXPRESS OR IMPLIED. ROMEO DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
					LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
					NON-INFRINGEMENT.</p>
				<h5>7. Limitation of Liability</h5>
				<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ROMEO SHALL NOT BE LIABLE FOR ANY INDIRECT,
					INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR
					LOSS OF PROFITS, REVENUE, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR IN
					CONNECTION WITH THE USE OR INABILITY TO USE THE SERVICE.</p>
				<h5>8. Governing Law</h5>
				<p>These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without
					regard to its conflict of laws provisions.</p>
				<h5>9. Changes to Terms</h5>
				<p>Romeo reserves the right to modify or revise these Terms at any time. Your continued use of the
					Service after any such changes shall constitute your acceptance of the modified Terms.</p>
				<h5>10. Contact Us</h5>
				<p>If you have any questions about these Terms, please contact us at <a href="mailto:support@romeo.com">support@romeo.com</a>.
				</p>
			</div>
		</div>
	</layoutProfile>
</template>

<style scoped lang="scss">

</style>